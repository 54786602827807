<template>
  <th-page-wrapper>
    <template #actions>
      <div class="p-4 h-full flex justify-end justify-items-end items-center">
        <!-- Template -->
        <div class="flex-grow">
          <el-select
            v-model="value"
            :placeholder="$t('pages.document_designer.select')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :no-match-text="$t('pages.document_designer.noMatchText')"
              :value="item.value"
            />
          </el-select>
        </div>

        <!-- Download -->
        <el-button
          :class="{ active: !downloadDisabled }"
          icon="Download"
          class="el-button--text-icon"
          :disabled="downloadDisabled"
          @click="download"
        />

        <!-- Preview -->
        <el-button :disabled="isButtonDisabled" @click="preview">
          {{ $t('pages.document_designer.preview') }}
        </el-button>

        <!-- Save -->
        <el-button
          v-permissions="{
            scopes: [
              'utilities:document_designer:create',
              'utilities:document_designer:update'
            ]
          }"
          :type="saved ? 'primary' : ''"
          @click="save"
        >
          {{ $t('pages.document_designer.save') }}
        </el-button>
      </div>
    </template>

    <router-view />
  </th-page-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'

export default {
  name: 'Templater',
  data() {
    const value = this.$route.name || 'invoices'
    return {
      value,
      loading: false
    }
  },
  computed: {
    options() {
      return [
        {
          value: 'invoices',
          label: this.$t('pages.document_designer.invoices')
        },
        {
          value: 'full_receipts',
          label: this.$t('pages.document_designer.fullReceipts')
        },
        {
          value: 'delivery_notes',
          label: this.$t('pages.document_designer.deliveryNotes')
        }
      ].filter((opt) => this.$isFeatureEnabled(opt.value))
    },
    isButtonDisabled() {
      return (
        !this.templateOptions ||
        !Object.keys(this.templateOptions).length ||
        !this.templateId
      )
    },
    downloadDisabled() {
      return !this.previewUrl
    },
    ...mapState({
      saved(state) {
        return state.Templates.isDirty
      },
      templateOptions(state) {
        return (
          state.Templates.currentTemplate &&
          state.Templates.currentTemplate.options
        )
      },
      templateId(state) {
        return (
          state.Templates.currentTemplate && state.Templates.currentTemplate.id
        )
      },
      previewUrl(state) {
        return state.Templates.previewUrl
      }
    })
  },
  watch: {
    value: function (newValue, oldValue) {
      this.$store.commit('Templates/setPreviewUrl', null)
      this.$router.push(newValue)
    }
  },
  created() {
    this.$store.dispatch('Templates/clear')
  },
  async mounted() {
    try {
      await this.$store.dispatch('Templates/fetchTemplates', { vm: this })

      if (this.$route.name === 'invoices') {
        this.$store.dispatch(
          'Templates/setCurrentTemplate',
          this.$store.getters['Templates/getInvoices'][0]
        )
      }

      if (this.$route.name === 'delivery_notes') {
        this.$store.dispatch(
          'Templates/setCurrentTemplate',
          this.$store.getters['Templates/getDeliveryNotes'][0]
        )
      }

      if (this.$route.name === 'full_receipts') {
        this.$store.dispatch(
          'Templates/setCurrentTemplate',
          this.$store.getters['Templates/getFullReceipts'][0]
        )
      }
    } catch (err) {
      this.$notify.error({
        title: 'Error',
        message: err.message
      })
    }
  },
  methods: {
    getType() {
      if (this.$route.name === 'delivery_notes') return 'delivery_note_v1'
      if (this.$route.name === 'invoices') return 'invoice_v1'
      if (this.$route.name === 'full_receipts') return 'full_receipt_v1'
    },
    async save() {
      this.loading = true
      try {
        await this.$store.dispatch('Templates/alterTemplate', {
          type: this.getType(),
          vm: this
        })
        this.$notify.success({
          title: 'Success',
          message: 'Template successfully saved.'
        })
      } catch (err) {
        this.$notify.error({
          title: 'Error',
          message: err.message
        })
      }
      this.loading = false
    },
    async preview() {
      if (!this.templateId) {
        return this.$notify.error({
          title: 'Error',
          message: 'Cannot preview without a template'
        })
      }

      try {
        await this.$store.dispatch('Templates/preview', { vm: this })
      } catch (err) {
        this.$notify.error({
          title: 'Error',
          message: err.message
        })
      }
    },
    async download() {
      if (!this.previewUrl) {
        return this.$notify.error({
          title: 'Error',
          message: 'Preview url missing'
        })
      }

      saveAs(this.previewUrl, `${this.getType()}-${this.templateId}.pdf`)
    }
  }
}
</script>

<style scoped>
.el-footer {
  background-color: #b3c0d1;
  color: #333;
}

.el-header {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
}

.el-header {
  display: flex;
  align-items: center;
}

.main-wrap {
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.el-main {
  background-color: var(--templater-gray);
  color: #333;
  padding: 0 !important;
  height: 100%;
}

.el-menu-item.is-active {
  outline: none;
}

.el-upload-dragger {
  display: flex;
  justify-content: center;
  align-items: center;
}

label.form {
  color: white;
  font-family: Monaco, 'Courier New', Courier, monospace;
  font-weight: bold;
  margin-bottom: 2em;
  display: block;
}
input {
  padding: 0.45em;
  font-size: 1em;
}
.error {
  border: 1px solid red;
  background: pink;
  color: red;
  padding: 0.5em 3em;
  display: inline;
}

a.icon {
  cursor: pointer;
  display: block;
  border: 1px #333 solid;
  background: white;
  color: #333;
  font-weight: bold;
  padding: 0.25em;
  width: 1em;
  height: 1em;
  font-size: 1.5em;
}

.box-shadow {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
.overflow-hidden {
  overflow: hidden;
}

.el-icon-download {
  margin-right: 20px;
  cursor: pointer;
  color: var(--secondary-color);
}

.el-icon-download.active:hover {
  color: #279ff6;
}

.el-icon-download.disabled {
  color: lightgrey;
  cursor: default;
}
</style>
